    <template>
  <b-card-code title="Reset Password">
    <!-- form -->
    <validation-observer ref="ResetPasswordForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="resetpassword">
        <!-- Old password -->
        <b-form-group label="Old Password" label-for="oldPassword">
          <validation-provider
            #default="{ errors }"
            name="oldPassword"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="lastPassword"
                :state="errors.length > 0 ? false : null"
                :type="password1FieldType"
                class="form-control-merge"
                name="oldPassword"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password1ToggleIcon"
                  @click="togglePassword1Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Password" label-for="Password">
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="password"
                @input="password_check"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                class="form-control-merge"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <div class="row mt-1">
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': password.length > 9 }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="password.length ? 'fa-check' : 'fa-times'"
                />
                At least 10 characters
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_uppercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_uppercase ? 'fa-check' : 'fa-times'"
                />
                Has a capital letter
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_lowercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_lowercase ? 'fa-check' : 'fa-times'"
                />
                Has a lowercase letter
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_number }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_number ? 'fa-check' : 'fa-times'"
                />
                Has a number
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_special }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_special ? 'fa-check' : 'fa-times'"
                />
                Has a special character
              </p>
            </div>
          </div>
        </b-form-group>
        <!-- confirm password -->
        <b-form-group
          label="Confirm Password"
          label-for="reset-password-confirm"
        >
          <validation-provider
            #default="{ errors }"
            name="confirm password"
            rules="required|confirmed:Password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="reset-password-confirm"
                v-model="cPassword"
                :state="errors.length > 0 ? false : null"
                :type="password2FieldType"
                class="form-control-merge"
                name="reset-password-confirm"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password2ToggleIcon"
                  @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="
            invalid ||
            (password !== '' && !has_number) ||
            (password !== '' && !has_lowercase) ||
            (password !== '' && !has_uppercase) ||
            (password !== '' && !has_special) ||
            (password !== '' && password.length < 10)
          "
          size="sm"
        >
          Save
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      id: this.$store.state.app.user.id,
      password: "",
      cPassword: "",
      lastPassword: "",
      required,
      password2FieldType: "password",
      password1FieldType: "password",
      passwordFieldType: "password",

      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    };
  },
  methods: {
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    resetpassword: function () {
      this.$refs.ResetPasswordForm.validate().then((success) => {
        if (success) {
          //call to axios
          let data = {
            password: this.password,
            last_password: this.lastPassword,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "user/users/" +
              this.id +
              "/reset-password/",
          };
          var self = this;
          this.$http(options).then((res) => {
            if (res.data.status == "success") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              self.$router.push({ name: "login" });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
        }
      });
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
<style lang="scss">
input[type="password"]::-ms-reveal {
  display: none;
}
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>